/**
 * Show Formatter.
 */
window.showFormatter = function(value, row) {
    let html = '';
    if (value) {
        html += '<a href="' + value + '">';
        html += '<i class="fontawesome fas fa-eye"></i>';
        html += '</a>';
    }
    return html;
}
